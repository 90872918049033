<template>
  <div
    class="showCommonTemplate_container"
    :class="{ showCommonTemplate_container_only_background: isOnlyTitle() }"
  >
    <div
      class="showCommonTemplate_title"
      v-if="hasTitle()"
      :class="
        isOnlyTitle()
          ? 'showCommonTemplate_only_title'
          : getNotOnlyTitleStyle()
      "
    >
      {{ this.pageItem.content[0].class_content }}
    </div>
    <div
      class="showCommonTemplate_content"
      :style="getContentBackground()"
      :class="!hasImgs() ? 'showCommonTemplate_content_noImg' : ''"
      v-if="hasContent() && !hasImgs()"
    >
      <ContentBorder
        :content="this.pageItem.content[1].class_content"
        :iconName="getIconImg()"
        :project="getProject()"
      ></ContentBorder>
    </div>
    <div
      class="showCommonTemplate_content showCommonTemplate_content_hasContentAndImgs"
      :style="getContentBackground()"
      v-if="!hasTitle() && hasContent() && hasImgs()"
    >
      <contentBorderTow
        :content="this.pageItem.content[1].class_content"
        :iconName="getIconImg()"
        :project="getProject()"
      ></contentBorderTow>
    </div>
    <div
      class="showCommonTemplate_content showCommonTemplate_content_hasAll"
      :style="getContentBackground()"
      v-if="hasTitle() && hasContent() && hasImgs()"
    >
      {{ this.pageItem.content[1].class_content }}
    </div>
    <div class="showCommonTemplate_images" v-if="hasImgs()">
      <displayImages :images="imgList"></displayImages>
    </div>
    <div class="global_statement_item" :class="{ statement_item: isOnlyTitle() }">
      <div class="global_statement">{{ $config.statement }}</div>
    </div>
  </div>
</template>
<script>
import ContentBorder from "@/components/unit/border/contentBorder";
import contentBorderTow from "@/components/unit/border/contentBorderTow";
import displayImages from "@/components/unit/displayImages";
export default {
  name: "showCommonTemplate",
  props: {
    pageItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    meetInfo:{
        type:Object,
        default:()=>{
            return {}
        }
    }
  },
  data() {
    return {
      title: "",
      imgList: [],
      data: {},
    };
  },
  created() {
    this.getImages();
  },
  mounted() {},
  components: {
    ContentBorder,
    contentBorderTow,
    displayImages,
  },
  methods: {
    getProject(){
        let key = this.meetInfo?.main_color || "9e004b"
        return this.$config.theme[key].name;
    },
    getNotOnlyTitleStyle(){
       let project = this.getProject();
       let cls = "";
       switch (project) {
        case "pubeixi":
            cls = "pubeixi_showCommonTemplate_not_only_title"
            break;
        default:
            cls = "showCommonTemplate_not_only_title"
            break;
       }
       return cls;
    },
    getContentBackground()
    {
       let project = this.getProject();
       let Background = "";
       switch (project) {
        case "pubeixi":
            Background = "background:linear-gradient(#fff,#daeefc);"
            break;
        default:
            Background = "background:linear-gradient(#fff,#f6e7ee);"
            break;
       }
       return Background;
    },
    hasTitle() {
      return !!this.pageItem.content[0].class_content;
    },
    hasContent() {
      return !!this.pageItem.content[1].class_content;
    },
    hasImgs() {
      return !!this.pageItem.content[2].class_content;
    },
    isOnlyTitle() {
      return this.pageItem.content[1].class_content ||
        this.pageItem.content[2].class_content
        ? false
        : true;
    },
    getIconImg() {
        let project = this.getProject();
        let prefix = project?`${project}_`:"";
        return this.hasTitle() ? `${prefix}content_one.png` : `${prefix}content_four.png`;
    },
    getImages() {
      this.imgList =
        this.pageItem.content[2]?.class_content?.split(",").filter((x) => x != "") || [];
    },
  },
};
</script>
<style>
.showCommonTemplate_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.showCommonTemplate_container_only_background {
  background: #434343;
}
.showCommonTemplate_title {
  width: 100%;
}
.showCommonTemplate_not_only_title {
  flex: 16.4vw 0 0;
  font-size: 4.2vw;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 5.6vw;
  background: linear-gradient(#9e004b, #cb6e9b);
}
.pubeixi_showCommonTemplate_not_only_title{
  flex: 16.4vw 0 0;
  font-size: 4.2vw;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 5.6vw;
    background:linear-gradient(#004890,#4f7db5) ;
}
.showCommonTemplate_only_title {
  font-size: 4.8vw;
  font-weight: 600;
  background: #fff;
  flex: 27vw 0 0;
  color: #990041;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showCommonTemplate_content {
  width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  padding: 5vw;
  /* background: linear-gradient(#fff, #f6e7ee); */
}
.showCommonTemplate_content_hasAll {
  flex: 20.8vw 0 0;
  padding: 0 6vw;
  display: flex;
  align-items: center;
  background: #fff;
}
.showCommonTemplate_content_noImg {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  padding: 0 6vw;
}
.showCommonTemplate_content_hasContentAndImgs {
  /* flex: 39.3vw 0 0; */
}
.showCommonTemplate_images {
  flex: 1 0 0;
}
.statement_item {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
