<template>
    <div class="contentBorderTow_container" :style="getBorder()">
        {{content}}
    <div class="contentBorderTow_iconBlock contentBorderTow_iconBlock_left_top" :style="getLeftTopBackground()">
        <div class="contentBorderTow_point" :style="getBorderPoint()"></div>
        <div class="contentBorderTow_icon">
            <img :src="require(`@/assets/images/meeting/${iconName}`)" />
        </div>
        <div class="contentBorderTow_point" :style="getBorderPoint()"></div>
    </div>
    <div class="contentBorderTow_iconBlock contentBorderTow_iconBlock_right_bottom" :style="getRightBottomBackground()">
        <div class="contentBorderTow_point" :style="getBorderPoint()"></div>
        <div class="contentBorderTow_icon">
            <img :src="require(`@/assets/images/meeting/${iconName}`)" />
        </div>
        <div class="contentBorderTow_point" :style="getBorderPoint()"></div>
    </div>
    </div>
</template>
<script>
export default {
    name:"contentBorderTow",
    props:{
        iconName:{
            type:String,
            default:""
        },
        project:{
            type:String,
            default:""
        },
        content:{
            type:String,
            default:""
        }
    },
    data(){
        return {
            data:{
                
            }
        }
    },
    methods:{
        getBorder(){
            let style = ""
            switch (this.project) {
                case "mutai":
                    style = "border: 1px solid #80b25f;"
                    break;
                case "pubeixi":
                    style = "border: 1px solid #004890;"
                    break;
                default:
                    style = "border: 1px solid #9d004c;"
                    break;
            }
          return style;
        },
        getBorderPoint(){
            let style = ""
            switch (this.project) {
                case "mutai":
                    style = "background-color: #80b25f;"
                    break;
                case "pubeixi":
                    style = "background-color: #004890;"
                    break;
                default:
                    style = "background-color: #9d004c;"
                    break;
            }
          return style;
        },
        getBackground(){
            let style = ""
            switch (this.project) {
                case "mutai":
                    style = "background: linear-gradient(#f7fbee,#f1f9e2);";
                    break;
                case "pubeixi":
                    style = "background: linear-gradient(#f6fafe,#eff8fe);";
                    break;
                default:
                    style = "background:linear-gradient(#fcf8fa,#fcf5f8);";
                    break;
            }
          return style;
        },
        getLeftTopBackground(){
            let style = ""
            switch (this.project) {
                case "mutai":
                    style = "background: linear-gradient(#fbfdf6, #f4fae8);";
                    break;
                case "pubeixi":
                    style = "background: linear-gradient(#f6fafe,#eff8fe);";
                    break;
                default:
                    style = "background:linear-gradient(#fcf8fa,#fcf5f8);";
                    break;
            }
          return style;
        },
        getRightBottomBackground(){
            let style = ""
            switch (this.project) {
                case "mutai":
                    style = "background: linear-gradient(#e9f5d2, #e3f3c4);";
                    break;
                case "pubeixi":
                    style = "background: linear-gradient(#e7f4fd, #dff0fd)";
                    break;
                default:
                    style = "background:linear-gradient(#f9eef3,#f7eaf0);";
                    break;
            }
          return style;
        }
    }
}
</script>
<style>
.contentBorderTow_container{
    position: relative;
    width: 100%;
    border-radius: 1vw;
    border: 1px solid #9d004c;
    padding: 2.8vw;
}

.contentBorderTow_iconBlock{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(252, 245, 248 0);
    z-index: 100;
    width: 8vw;
}
.contentBorderTow_iconBlock_left_top{
    position: absolute;
    top: -2.1vw;
    left: 2.8vw;
    /* background:linear-gradient(#fcf8fa,#fcf5f8); */
}
.contentBorderTow_iconBlock_right_bottom{
    position: absolute;
    bottom: -2.1vw;
    right: 2.8vw;
    /* background:linear-gradient(#f9eef3,#f7eaf0); */
}
.contentBorderTow_icon{
    height: 4vw;
    width: 3vw;
}
.contentBorderTow_icon img{
    width: 100%;
    height: 100%;
}
.contentBorderTow_point{
    background-color: #9d004c;
    width: 1.6vw;
    height: 1.6vw;
    border-radius: 0.8vw;
}
</style>